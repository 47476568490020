<template>
    <v-app id="bg-app">
        
        <img src="/img/Group-20.png" class="img-top" width="100%">
        <img src="/img/G.png" alt="" srcset="" class="center-img2">
        
        <div class="pay">
            <div class="rp">
                Rp. 4.500.000
            </div>
            <div class="text">
                Kalo kamu tertarik, yuk cobain 3 bulan pertama untuk rapihin gigimu! #SenyuminAja!
            </div>
        </div>

        <div class="btn-unbox">
            <div class="label">
                <a href="https://freedomaligner.com/" style="color:#FFA225;"> BACK TO LANDING PAGE </a>
            </div>
        </div>

        <img src="/img/Group22.png" class="bg-buttom" width="100%">
    </v-app>
</template>

<script>
export default {
  name: 'SubmitQuestion',
  methods: {
    changeColor(){
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#F37679");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>


<style lang="stylus">
    @font-face {
        font-family: Caveat;
        src: url('/fonts/Caveat.ttf');
    }
    @font-face {
        font-family: CaveatBrush;
        src: url('/fonts/CaveatBrush.ttf');
    }
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    .img-top{
        position : fixed;
        top: 0px;
    }

    #bg-app {
        width: 100%;
        height: 100%;
        position : fixed;
        z-index : 87;
        padding-top : 120px;
    }
    .bg-buttom{
        position :absolute;
        bottom : 0;
        z-index : 87;
        position : fixed;
        bottom : 0;
    }
    .center-img2{
        width : 337px;
        text-align :center;
        margin: 0 auto;
        margin-top : -60px;
        z-index :99;
    }
    .img-panah{
        width : 270px;
        text-align :center;
        margin: 0 auto;
        margin-top : -20px;
        padding-right : 30px;
        z-index :99;
    }
    .pay{
        background-color : #FFA225;
        text-align :center;
        z-index : 99;
        color : #1E2184;
        font-family : Poppins;
    }
    .pay .rp{
        font-size: 24px;
        font-weight : bold;
        margin-top : 15px; 
        margin-bottom : 5px; 
    }
    .pay .text{
        font-size: 16px;
        padding-bottom : 7px;
    }
    .btn-unbox{
        margin: 0 auto;
        margin-top : 10px;
        border-radius: 9999px;
        background-color  #313D8E;
        color : #FFA225;
        z-index : 99;
        font-family : Poppins;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30.9209px;
    }

    .btn-unbox .label{
        padding:5px;
    }
</style>